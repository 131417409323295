import React from "react";
import serviceDetails from "@/images/services/serviceDetails.jpeg";
import Img from "./img";

const ServicePost = () => {
  return (
    <div className="service_details_left">
      <Img src={serviceDetails} alt="Service Details Image" />
      <h1>Servisiranje klima uređaja</h1>
      <p>
      Prvo i osnovno što treba da utvrdite je koliko često je servis klima uređaja neophodan. 
      U nekim slučajevima neophodan je jednom godišnje a u nekim, posebno na zagađenim gradskim lokacijama ili ukoliko ga tokom cele godine koristite, i dva puta godišnje - na šest meseci.
      Prvi razlog zbog kojih je redovan servis neophodan je zdravstvene prirode, a zatim higijenske, i takođe preventivne, radi očuvanja uređaja.
      Servis klima uređaja podrazumeva: proveru ispravnosti uređaja, proveru elektronike, spojeva, stanja freona i eventualnu dopunu,proveru pritiska, 
      zamenu potrošenih creva za odvod i izolacije na cevima, podmazivanje ležajeva, razne sitne popravke uz obavezno čišćenje i dezinfikovanje unutrašnje i spoljašnje jedinice.
      </p>
      <h2>Problemi koji se često javljaju</h2>
      <ul>
        <li>
          <i className="fa fa-check-circle"></i> Klima uređaj se neće isključiti
        </li>
        <li>
          <i className="fa fa-check-circle"></i> Čudni zvukovi ili vibracije prilikom rada
        </li>
        <li>
          <i className="fa fa-check-circle"></i> Unutrašnja jedinica bruji i ventilator se ne iskljućuje
        </li>
        <li>
          <i className="fa fa-check-circle"></i> Ventilator radi ali spoljna jedinica ne radi
        </li>
        <li>
          <i className="fa fa-check-circle"></i> Curenje vode na klima uređaju
        </li>
        <li>
          <i className="fa fa-check-circle"></i> Pojavljivanje greške na displeju
        </li>
      </ul>
      <p className="extra_mr">
        Ako verujete da je vašem klima uređaju potreban servis, kontaktirajte nas.
      </p>
      <h2>Da li da popravim ili zamenim svoj klima uređaj?</h2>
      <ul className="service_details_ac">
        <li>
          <i className="fa fa-check-circle"></i> Starost - Ako je vaš klima uređaj stariji od 10 godina i zadaje 
          vam probleme, možda je u vašem interesu da razmislite o zameni.
        </li>
        <li>
          <i className="fa fa-check-circle"></i> Investicija - Da li je novi uređaj bolja investicija na duge staze?
          Naš tim vam može pomoći da izračunate isplativost ulaganja kako biste utvrdili da li je 
          popravka isplativija od ugradnje novog uređaja.
        </li>
        <li>
          <i className="fa fa-check-circle"></i> Cena struje - Možda mislite da ne možete priuštiti zamenu klima uređaja,
          ali noviji i efikasniji uređaji troše mnogo manje električne energije i mogu vam vremenom
          uštedeti dosta novca.
        </li>
      </ul>
      {/* <p className="info_mr">
        Visit our <a href="#">air conditioner maintenance page</a> for helpful
        tips and information.
      </p> */}
    </div>
  );
};

export default ServicePost;

import React from "react";

const ContactTwo = () => {
  return (
    <section className="contact_form_area ctf_gray section_padding">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="contact-right contact_details">
              <div className="hero-title-with-shape">
                <h4 className="heading_with_border">Dobijte besplatnu ponudu</h4>
                <h1>
                Popunite formu kako bi Vas neko iz servisa kontaktirao                </h1>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="contact-right contact-right-style-2">
              <form action="#" method="post">
                <input
                  type="text"
                  placeholder="Vaše ime"
                  className="half_width input_m_right"
                />
                <input
                  type="EMAIL"
                  placeholder="Email adresa"
                  className="half_width"
                />
                <input type="tel" placeholder="Broj telefona" />
                <span className="select_icon">
                  <select name="select" id="select">
                    <option value="option0">Odaberite uslugu</option>
                    <option value="option1">Servisiranje klima uređaja</option>
                    <option value="option2">Instalacija klima uređaja</option>
                    <option value="option3">Prodaja klima uređaja</option>
                    <option value="option4">Tehnička podrška</option>
                  </select>
                </span>
                <button className="btn-yellow" value="SUBMIT NOW" disabled>
                  POŠALJITE
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactTwo;
